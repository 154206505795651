// i18next-extract-mark-ns-start about-us-events

import { LINKS } from '@utils/constants';
import { IEventFrontmatter } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Deck from '@components/Deck';
import Event from '@components/Event';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const EventPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const upcomingEvents = data.events.edges.filter(({ node }: { node: IEventFrontmatter }) => {
		const startOfToday = new Date();
		startOfToday.setHours(0, 0, 0, 0);

		if (node.frontmatter.endDate) {
			return new Date(node.frontmatter.endDate) >= startOfToday
		}

		if (node.frontmatter.date) {
			return new Date(node.frontmatter.date) >= startOfToday
		}

		return false;
	});

	const previousEvents = data.events.edges.filter(({ node }: { node: IEventFrontmatter }) => {
		const startOfToday = new Date();
		startOfToday.setHours(0, 0, 0, 0);

		if (node.frontmatter.endDate) {
			return new Date(node.frontmatter.endDate) < startOfToday
		}

		if (node.frontmatter.date) {
			return new Date(node.frontmatter.date) < startOfToday
		}

		return false;
	});

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: 'Events.',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__events" layout="wide">
				<h2><Trans i18nKey="future_events">Upcoming events</Trans></h2>
				<Deck>
					{upcomingEvents.map(
						({ node }: { node: IEventFrontmatter }, i: number) => (
							<Event
								key={i}
								{...node.frontmatter}
								link={`${LINKS.ABOUT_EVENTS}${node.fields.slug}`}
							/>
						)
					)}
				</Deck>
				<h2><Trans i18nKey="past_events">Previously attended Events</Trans></h2>
				<Deck>
					{previousEvents.map(
						({ node }: { node: IEventFrontmatter }, i: number) => (
							<Event
								key={i}
								{...node.frontmatter}
								link={`${LINKS.ABOUT_EVENTS}${node.fields.slug}`}
							/>
						)
					)}
				</Deck>
			</PageSection>
		</Page>
	);
};

export default EventPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["about-us-events", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/events/Realis-Simulation_Events_cropped.jpg" }) {
		...imageBreaker
	}
	events: allMarkdownRemark(
		filter: { 
			fileAbsolutePath: { regex: "/events/" } 
			fields: { language: { eq: $language } }
		}
		sort: { fields: frontmatter___date, order: DESC }
	) {
		edges {
			node {
				fields {
					slug
				}
				frontmatter {
					title
					event
					summary
					country
					date(formatString: "DD MMMM YYYY")
					endDate(formatString: "DD MMMM YYYY")
					tileImage {
						...imageStandard
					}
					pageImage {
						...imageStandard
					}
				}
			}
		}
	}
}
`;