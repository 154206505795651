import { formatDateRange } from '@utils/helpers';
import { IEvent } from '@utils/types';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Event = ({ title, event, country, date, endDate, tileImage, link }: IEvent) => {
	const gatsbyImage = getImage(tileImage.childImageSharp.gatsbyImageData);

	const { t, i18n } = useTranslation();

	return (
		<article className="card event-card">
			{gatsbyImage && (
				<div className="event-card__image">
					<GatsbyImage
						image={gatsbyImage}
						alt={title}
						objectFit="contain"
					/>
				</div>
			)}
			<div className="event-card__content">
				<h3 className="event-card__title">{event}</h3>
				<p className="event-card__location">{country}</p>
				{date && <span className="event-card__date">
					{formatDateRange(date, endDate, i18n.resolvedLanguage)}
				</span>}

				<div className="event-card-actions">
					<span className="event-card__button button--more is-inverted is-clean">{t("_ViewEvent")}</span>
				</div>
			</div>
			<Link to={link} className="link-coverer event-card__link">
				{t("_ViewEvent")}
			</Link>
		</article>
	);
};

export default Event;
